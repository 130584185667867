<template>
  <!--  <Header/>-->
  <Body msg="Welcome to Your ChatGPT"/>
</template>

<script>
import Body from './components/body.vue'

export default {
  name: 'App',
  components: {
    Body
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background: #101010;
}
</style>
