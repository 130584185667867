<template>
  <div id="home">

    <div id="div-header">
      <div style="width: 10px"></div>
            <img style="width: 36px;height: 36px;margin-right: 4px" src="../assets/ic_launcher_trans.png"/>
      <h2 id="h2">GodCode Apps</h2>
      <h4 id="h2" @click="privacy">Privacy</h4>
      <a target="_blank" style="background: transparent;color: white; text-decoration: none;"
         href="https://qm.qq.com/cgi-bin/qm/qr?k=eRH9HvvCGMm4QA_FOhTX5iV2Y5_LfGNg&jump_from=webapi&authKey=o3ys71XjR0+pnVKzXTulevIrJ9Eg6VFUca7sPEFD9RYjzrY8UMXP6WcI5Lx08tcL">
        <h4>
          Contact
        </h4>
      </a>
    </div>

    <h1 style="left: 0">ChatGPT</h1>
    <div>
      <img id="img-store" src="../assets/apk-zh.png" @click="openApkZh()">
      <img id="img-store" src="../assets/app-store.png" @click="openAppStore()">
      <img id="img-store" src="../assets/google-play.png" @click="openGooglePlay()">

      <img id="img-store" src="../assets/windows.jpg" style="border-radius: 6px" @click="openWindows()">
      <img id="img-store" src="../assets/web.jpg" style="border-radius: 6px"
           @click="openExternalLink('https://chat.firefix.cn')">


    </div>

    <div id="div-body">
      <div id="div-screen">
        <img :id="isMobile?'mobile-screen-img':'screen-img'" src="../assets/screen1.jpeg">
        <img :id="isMobile?'mobile-screen-img':'screen-img'" src="../assets/screen2.jpeg">
      </div>
      <p id="p" v-if="!isMobile">{{ desc }}
      </p>
    </div>
    <p id="mobile-p" v-if="isMobile">{{ desc }}
    </p>

  </div>
</template>

<script>

export default {
  name: 'AI Talk GPT',
  props: {
    msg: String
  },
  data() {
    return {
      isMobile: false,
      desc: "ChatGPT is a chat application based on artificial intelligence technology. It uses state-of-the-art natural language processing algorithms that can simulate human conversations to understand and answer various questions posed by users. Whether looking for answers, getting entertainment, or interacting with friends, GodCode Apps development team members have extensive experience and excellent technical literacy to develop the most forward-looking and practical applications. We will continue to launch innovative products, constantly challenge ourselves and meet the needs of users, and create more possibilities for life in the digital age."
    }
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    openGooglePlay() {
      let url = 'https://play.google.com/store/apps/details?id=com.fast.ai.chat'
      this.openExternalLink(url)
    },
    openAppStore() {
      let url = 'https://apps.apple.com/hk/app/id6450460445?l=zh-tw&platform=iphone'
      this.openExternalLink(url)
    },
    openWindows() {
      var a = document.createElement('a')
      a.download = name || 'apk'
      a.href = 'https://img.firefix.cn/chat/chatgpt_windows.zip';
      a.click();
    },
    openApkZh() {
      var a = document.createElement('a')
      a.download = name || 'apk'
      a.href = 'https://img.firefix.cn/chat/app-release.apk';
      a.click();
    },
    privacy() {
      window.location.href = "https://ai.firefix.cn/privacy.html"
    },
    openExternalLink(url) {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') !== -1) {
        // 在微信中打开外部链接
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        // 在其他浏览器中打开外部链接
        window.open(url);
      }
    },
    showToast(msg, duration) {
      duration = isNaN(duration) ? 3000 : duration;
      var m = document.createElement('div');
      m.innerHTML = msg;
      m.style.cssText = "width:50%; min-width:40px; background:#000; opacity:0.7; text-align:center; height:auto;min-height: 50px; color:#fff; line-height:50px; text-align:center; border-radius:4px; position:fixed; top:50%; left:20%; right:20%; z-index:999999; font-size: medium;";
      document.body.appendChild(m);
      setTimeout(function () {
        var d = 0.5;
        m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';
        m.style.opacity = '0';
        setTimeout(function () {
          document.body.removeChild(m)
        }, d * 1000);
      }, duration);
    }
  }

}
</script>
<style scoped>
#home {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;

}

#img-bg {
  width: 0%;
  object-fit: contain;
  background: transparent;
}

#div-body {
  display: flex;
  width: 100vw;
  flex-direction: row;
}

#div-header {
  display: flex;
  width: 100vw;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

#h2 {
  margin-right: 0.8rem
}

#div-screen {
  margin-top: 1.6rem;
}

#mobile-screen-img {
  width: 150px;
}

#screen-img {
  width: 20rem;
}

#img-store {
  height: 40px;
  margin: 0.4rem;
}

#div-bottom {
  position: absolute;
  bottom: 0;
  max-width: 768px;
  display: flex;
  text-align: start;
  line-height: 1.6rem;
  font-size: 0.8rem;
  margin: 15px;
  color: azure;
}

#p {
  max-width: 568px;
  display: flex;
  text-align: start;
  line-height: 2.5rem;
  font-size: 1.4rem;
  margin: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

#mobile-p {
  max-width: 768px;
  display: flex;
  text-align: start;
  line-height: 1.2rem;
  font-size: 0.8rem;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

</style>
